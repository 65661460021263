import * as Vue from 'vue';
export default {
  name: 'App',
  components: {},

  mounted() {},

  beforeRouteEnter(to, from, next) {
    console.log(to, from);
    next();
  }

};